import {BehaviorSubject, map, Subject, takeUntil, withLatestFrom} from 'rxjs';
import {
  FacetConfig,
  FacetField,
  FacetType,
  RequestState,
  SortOption,
} from '@app/models';

export const defaultRequestState = {
  limit: 20,
  page: 1,
  sort: {field: 'min_price', direction: 'asc'} as SortOption,
  filters: [] as {FacetName: FacetField; FacetValues: string[]}[],
};

export class SearchDriver {
  private defaultRequestState = defaultRequestState;

  config: FacetConfig;

  // permanent filters are transparent:
  // - not visible in UI
  // - not visible in URL
  // - not returned in facets$
  readonly permanentFilters: {
    FacetName: FacetField;
    FacetValue: string;
  }[] = [];

  // facet state (independent of requestState$ filters)
  facets$: BehaviorSubject<
    {
      FacetName: FacetField;
      FacetValues: string[];
    }[]
  >;

  // request state
  requestState$: BehaviorSubject<{
    limit: number;
    page: number;
    sort: SortOption;
    filters: {
      FacetName: FacetField;
      FacetValues: string[];
    }[];
  }>;

  doSyncFilters$ = new Subject<boolean>();

  private destroy$ = new Subject<void>();

  constructor(
    config: FacetConfig,
    initRequestState?: {
      limit?: number;
      page?: number;
      sort?: SortOption;
      filters?: {FacetName: FacetField; FacetValues: string[]}[];
    }
  ) {
    if (
      config.permanentFilters.some(permanentFilter =>
        config.facets
          .flatMap(facet => {
            switch (facet.type) {
              case FacetType.destination:
              case FacetType.duration:
              case FacetType.price:
                return facet.fields;
              default:
                return facet.field;
            }
          })
          .some(
            customFacetField => customFacetField === permanentFilter.FacetName
          )
      )
    ) {
      throw new Error('Permanent filters cannot be applied on Facet fields');
    }
    if (
      config.permanentFilters.some(
        (val, idx, arr) =>
          arr.findIndex(arrVal => arrVal.FacetName === val.FacetName) !== idx
      )
    ) {
      throw new Error(
        'Multiple permanent filters on same field is not supported'
      );
    }

    // initialize from config
    this.config = config;
    this.permanentFilters = config.permanentFilters;

    this.requestState$ = new BehaviorSubject({
      ...this.defaultRequestState,
      ...initRequestState,
    });

    this.facets$ = new BehaviorSubject(initRequestState?.filters ?? []);

    // one-way sync from requestState to facets$
    this.requestState$
      .pipe(
        map(requestState => requestState.filters),
        takeUntil(this.destroy$)
      )
      .subscribe(newFilters => {
        this.updateFilters(newFilters);
      });

    // manually update requestState filters from facets$ state
    this.doSyncFilters$
      .pipe(
        withLatestFrom(this.facets$, this.requestState$),
        takeUntil(this.destroy$)
      )
      .subscribe(([_, syncFacets, requestState]) => {
        this.requestState$.next({
          ...requestState,
          page: 1,
          filters: syncFacets,
        });
      });
  }

  // facets$ state methods
  updateFilters(newFilters: {FacetName: FacetField; FacetValues: string[]}[]) {
    const oldFilters = this.facets$.getValue();
    const changedFilters = !this.filterArraysEqual(oldFilters, newFilters);

    const configFields = this.config.facets.flatMap(facet => {
      switch (facet.type) {
        case FacetType.destination:
        case FacetType.duration:
        case FacetType.price:
          return facet.fields;
        default:
          return facet.field;
      }
    });
    const validFields = newFilters.every(newFilter => {
      return configFields.some(
        customFacetField => customFacetField === newFilter.FacetName
      );
    });
    if (changedFilters && validFields) {
      this.facets$.next(newFilters);
    }
  }

  addFilter(addFilter: {FacetName: FacetField; FacetValue: string}) {
    const oldFilters = this.facets$.getValue();
    // clone
    const updatedFilters = oldFilters.map(oldFilter => {
      return {
        FacetName: oldFilter.FacetName,
        FacetValues: oldFilter.FacetValues.map(v => v),
      };
    });
    let shouldUpdate = false;
    const configMatch = this.config.facets.find(customFacet => {
      switch (customFacet.type) {
        case FacetType.destination:
        case FacetType.duration:
        case FacetType.price:
          return customFacet.fields.some(
            customFacetField => customFacetField === addFilter.FacetName
          );
        default:
          return customFacet.field === addFilter.FacetName;
      }
    });
    if (configMatch) {
      const fieldMatch = updatedFilters.find(
        oldFilter => oldFilter.FacetName === addFilter.FacetName
      );
      if (fieldMatch) {
        const valueMatch = fieldMatch.FacetValues.some(
          val => val === addFilter.FacetValue
        );
        if (!valueMatch) {
          fieldMatch.FacetValues.push(addFilter.FacetValue);
          shouldUpdate = true;
        }
      } else {
        updatedFilters.push({
          FacetName: addFilter.FacetName,
          FacetValues: [addFilter.FacetValue],
        });
        shouldUpdate = true;
      }
    }

    if (shouldUpdate) {
      this.facets$.next(updatedFilters);
    }
  }

  removeFilter(removeFilter: {FacetName: FacetField; FacetValue: string}) {
    const oldFilters = this.facets$.getValue();
    // clone
    let updatedFilters = oldFilters.map(oldFilter => {
      return {
        FacetName: oldFilter.FacetName,
        FacetValues: oldFilter.FacetValues.map(v => v),
      };
    });
    let shouldUpdate = false;
    const match = updatedFilters.find(
      filter => filter.FacetName === removeFilter.FacetName
    );
    if (match) {
      match.FacetValues = match.FacetValues.filter(
        facetVal => facetVal !== removeFilter.FacetValue
      );
      if (match.FacetValues.length === 0) {
        updatedFilters = updatedFilters.filter(
          filter => filter.FacetName !== removeFilter.FacetName
        );
      }
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      this.facets$.next(updatedFilters);
    }
  }

  clearFilter(facetName: FacetField) {
    const oldFilters = this.facets$.getValue();
    // clone
    const updatedFilters = oldFilters.map(oldFilter => {
      return {
        FacetName: oldFilter.FacetName,
        FacetValues: oldFilter.FacetValues.map(v => v),
      };
    });
    let shouldUpdate = false;
    const matchIdx = updatedFilters.findIndex(
      oldFilter => oldFilter.FacetName === facetName
    );
    if (matchIdx > -1) {
      updatedFilters.splice(matchIdx, 1);
      shouldUpdate = true;
    }

    if (shouldUpdate) {
      this.facets$.next(updatedFilters);
    }
  }

  clearFilters() {
    const oldFilters = this.facets$.getValue();
    if (oldFilters.length !== 0) {
      this.facets$.next([]);
    }
  }

  // requestState$ methods
  // overwrite multiple requestState properties at once
  updateRequestState(stateUpdate: {
    limit?: number;
    page?: number;
    sort?: SortOption;
    filters?: {FacetName: FacetField; FacetValues: string[]}[];
  }) {
    const oldState = this.requestState$.getValue();
    const newState = {
      ...oldState,
      ...stateUpdate,
    };
    const changedStates = !this.requestStatesEqual(oldState, newState);
    const configFields = this.config.facets.flatMap(facet => {
      switch (facet.type) {
        case FacetType.destination:
        case FacetType.duration:
        case FacetType.price:
          return facet.fields;
        default:
          return facet.field;
      }
    });
    const validFields = newState.filters.every(newFilter => {
      return configFields.some(
        customFacetField => customFacetField === newFilter.FacetName
      );
    });
    if (changedStates && validFields) {
      this.requestState$.next(newState);
    }
  }

  updateLimit(newLimit: number) {
    const oldState = this.requestState$.getValue();
    const oldLimit = oldState.limit;
    if (newLimit <= 30 && newLimit !== oldLimit) {
      const newState = {...oldState, limit: newLimit};
      this.requestState$.next(newState);
    }
  }

  updatePage(newPage: number) {
    const oldState = this.requestState$.getValue();
    const oldPage = oldState.page;
    if (newPage !== oldPage) {
      const newState = {...oldState, page: newPage};
      this.requestState$.next(newState);
    }
  }

  // helpers
  requestStatesEqual(
    oldRequestState: RequestState,
    newRequestState: RequestState
  ) {
    if (
      oldRequestState.limit !== newRequestState.limit ||
      oldRequestState.page !== newRequestState.page ||
      oldRequestState.sort.direction !== newRequestState.sort.direction ||
      oldRequestState.sort.field !== newRequestState.sort.field
    )
      return false;

    if (
      !this.filterArraysEqual(oldRequestState.filters, newRequestState.filters)
    )
      return false;

    return true;
  }

  filterArraysEqual(
    filters1: {
      FacetName: FacetField;
      FacetValues: string[];
    }[],
    filters2: {
      FacetName: FacetField;
      FacetValues: string[];
    }[]
  ): boolean {
    return (
      filters1.length === filters2.length &&
      filters1.every((filter1Filter, idx) =>
        this.filtersEqual(filter1Filter, filters2[idx])
      )
    );
  }

  private filtersEqual(
    filter1: {
      FacetName: FacetField;
      FacetValues: string[];
    },
    filter2: {
      FacetName: FacetField;
      FacetValues: string[];
    }
  ): boolean {
    if (filter1.FacetName !== filter2.FacetName) return false;
    if (filter1.FacetValues.length !== filter2.FacetValues.length) return false;
    if (
      !filter1.FacetValues.every(
        (filter1Val, idx) => filter1Val === filter2.FacetValues[idx]
      )
    )
      return false;
    return true;
  }

  destroy() {
    this.destroy$.next();
  }
}
